import {Button, Card, Col, Row} from "react-bootstrap";
import horse_coin from "../assets/horse-coin.svg";
import BuyFltrButton from "./BuyFltrButton";

const Header = ({balance, connected, account, disconnectFromMetaMask,connectToMetaMask}) => {

    return <Card className={"border-0 mb-2"}>
        <Card.Body className={"p-0 m-0"}>
            <Row>
                <Col>
                    <Row>
                        <Col className={"pt-1 ps-4 small balance"}>
                            <span className={"fw-bolder"}>Balance:</span> {balance.toFixed(4)}<span
                            className={"small"}>FLTR</span>
                            <BuyFltrButton width={16}></BuyFltrButton>
                        </Col>
                        <Col className={"fw-bolder text-uppercase"}>
                                            <span style={{textShadow: "1px 1px 2px #152954", top: "-10px"}}
                                                  className={"header-text-blue text-warning position-absolute fs-3"}>Room 1: Select Your Racer Place Your Bet</span>
                        </Col>
                        <Col className={"d-flex justify-content-end login"}>
                            {connected ? (
                                <>
                                            <span
                                                className={"pt-1 ps-4 small"}>
                                                <img src={horse_coin} alt={account} className={"img-fluid me-1 mb-1"}
                                                     width={16}/>
                                                {`0x${account?.slice(2, 2)}...${account?.slice(-4)}`}</span>
                                    <button className={"btn btn-sm text-xsm text-danger"}
                                            onClick={disconnectFromMetaMask}>(Disconnect)
                                    </button>
                                </>

                            ) : <Button className={"btn btn-sm btn-success"}
                                        onClick={connectToMetaMask}>Connect
                                Wallet</Button>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card.Body>
    </Card>;
}

export default Header;
