import {Modal} from "react-bootstrap";

function Faq({show, handleClose}) {

    return <>
        <Modal show={show} onHide={handleClose} keyboard={true} size="lg">
            <Modal.Header className={"border-warning bg-warning-subtle shadow shadow-sm pt-1 pb-1"} closeButton>
                <Modal.Title className={"text-yellow-200-racing text-warning d-flex"}>
                    Frequently Asked Questions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"bg-gradient-yellow rounded-bottom-2"}>
                <h4>How will players receive their payments?</h4>
                <p>Players' payments are processed daily after 12:00 PM. During this time, all game results of the day are reviewed and confirmed. Subsequently, the rewards of the winning players are automatically delivered to their wallets.</p>

                <h4>What currency is used for placing bets?</h4>
                <p>Bets are made using the Full Throttle (FLTR) Token within the game, starting from August 1st, 2023. You can use this token for in-game payments.</p>

                <h4>How can I place bets?</h4>
                <p>After connecting your Metamask wallet to the site, you can place bets by adding FLTR Tokens to your account. There are options to place bets on various races and horses within the game. You can acquire FLTR Tokens from PancakeSwap or by using MetaMask Swap.</p>

                <h4>How are the winning probabilities determined?</h4>
                <p>The winning probabilities for each horse are determined based on their racing attributes, past performance, and special abilities. These probabilities are then converted into betting odds for the players.</p>

                <h4>How can the Jackpot be won?</h4>
                <p>To win the Jackpot, you need to accurately predict the finishing order of the 6 horses. If you correctly make this challenging prediction, you will win a substantial jackpot reward.</p>

                <h4>How are game results announced?</h4>
                <p>Race results are announced on the gaming platform after the races are completed and the results are confirmed. Immediately after a race is finished, the ranking of the horses is revealed. An explanation is provided to the players, specifying the winning horses and their corresponding betting odds.</p>

                <h4>Will my bets be automatically refunded?</h4>
                <p>Yes, in some cases, your bets may be automatically refunded. For example, if a race is canceled or if there is insufficient participation, your bets will be refunded.</p>

                <h4>How is in-game security ensured?</h4>
                <p>Advanced crypto technologies and security measures are employed to ensure the security of the game. User data and payments are encrypted and protected.</p>

                <h4>How can I get support for the game?</h4>
                <p>Usually, there is a support team available on the game platform. You can reach out to this support team to address your questions and issues. Additionally, the Frequently Asked Questions (FAQ) section can also be helpful.</p>
            </Modal.Body>
        </Modal>
    </>;
}

export default Faq;