import {Card, Col, Container, Row} from "react-bootstrap";
import {ToastContainer} from "react-toastify";
import {useState} from "react";
import Room from "./components/Room";
import logo from "./assets/logo.png";
import Faq from "./page/Faq";
import TermsOfConditions from "./page/TermsOfConditions";
import PrivacyPolicy from "./page/PrivacyPolicy";

const swapLink = "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x09824592081B87924b1B8371a747edfd369c06de";
function App() {
    const [showFaq, setShowFaq] = useState(false);
    const [showToc, setShowToc] = useState(false);
    const [showPP, setShowPP] = useState(false);
    const handleClose = () => { setShowFaq(false); setShowToc(false); setShowPP(false)};

    return (
        <div className="App">
            <ToastContainer position={"bottom-center"} /> {/* Toast container component */}

            <div className={"mt-4 container-fluid"}>
                <Room></Room>
            </div>
            <Container className={"mt-5"}>
                <Card>
                    <Card.Body className={"shadow-white"}>
                        <Row>
                            <Col className={"d-flex justify-content-left align-items-center"}>
                                <img src={logo} alt={"Phorse Race"} className={""} style={{width: "240px"}}/>
                            </Col>
                            <Col>
                                <div className={"list-group"}>
                                    <h6>Help</h6>
                                    <a href="#" onClick={() => setShowFaq(!showFaq)} className={"list-group-item small p-0 pt-1 pb-1 ps-3"}>FAQ</a>
                                    <a href={swapLink} target={"_blank"} className={"list-group-item small p-0 pt-1 pb-1 ps-3"} rel="noreferrer">Buy Full Throttle (FLTR)</a>
                                    <a href="#top" className={"list-group-item small p-0 pt-1 pb-1 ps-3"}>How to Play Horse Race</a>
                                </div>
                            </Col>
                            <Col>
                                <div className={"list-group"}>
                                    <h6>Docs</h6>
                                    <a href="#" onClick={() => setShowToc(!showToc)} className={"list-group-item small p-0 pt-1 pb-1 ps-3"} rel="noreferrer">Terms of Condition</a>
                                    <a href="#" onClick={() => setShowPP(!showPP)} className={"list-group-item small p-0 pt-1 pb-1 ps-3"} rel="noreferrer">Privacy Policy</a>
                                    <a href="#" className={"list-group-item small p-0 pt-1 pb-1 ps-3"}>How to Play Horse Race</a>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <Faq show={showFaq} handleClose={handleClose}></Faq>
            <TermsOfConditions show={showToc} handleClose={handleClose}></TermsOfConditions>
            <PrivacyPolicy show={showPP} handleClose={handleClose}></PrivacyPolicy>

        </div>
    );
}

export default App;
