import {Modal} from "react-bootstrap";

function PrivacyPolicy({show, handleClose}) {

    return <>
        <Modal show={show} onHide={handleClose} keyboard={true} size="lg">
            <Modal.Header className={"border-warning bg-warning-subtle shadow shadow-sm pt-1 pb-1"} closeButton>
                <Modal.Title className={"text-yellow-200-racing text-warning d-flex"}>
                    Terms Of Conditions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"bg-gradient-yellow rounded-bottom-2"}>
                <h2>Privacy Policy</h2>
                <p>Effective Date: Sat Aug 12 2023</p>

                <p>Thank you for using our Horse Racing Game (referred to as "the Game"). This Privacy Policy outlines
                    how we collect, use, and protect the personal data of players participating in the Game.</p>

                <h3>Data Collection and Usage</h3>
                <p>We collect and use only the player's cryptocurrency wallet address for the purpose of awarding prizes
                    and maintaining personal statistics related to the Game.</p>

                <h3>Data Sharing</h3>
                <p>We do not share any collected data with third parties.</p>

                <h3>Data Retention</h3>
                <p>Player data is stored until the player requests deletion. Players who wish to have their data removed
                    can do so by sending an email to [email address].</p>

                <h3>Data Security</h3>
                <p>Player payment data is stored as wallet transactions on the Binance Smart Chain (BSC) network.</p>

                <h3>Children's Data</h3>
                <p>We do not collect personal data from children.</p>

                <h3>Control and Options</h3>
                <p>We do not provide options for users to control data usage, sharing, or storage.</p>

                <h3>Data Updates and Deletion</h3>
                <p>Players can request data updates or deletion by contacting us via email.</p>

                <h3>Privacy Policy Updates</h3>
                <p>Any updates to our privacy policy will be displayed as notifications within the Game.</p>

                <h3>Terms and Definitions</h3>
                <p>The following terms are used in this Privacy Policy:</p>
                <ul>
                    <li><strong>Personal Data:</strong> Refers to the cryptocurrency wallet address of players.</li>
                    <li><strong>Cookies:</strong> Not applicable in this context.</li>
                    <li><strong>Third-party Service Providers:</strong> Not applicable in this context.</li>
                </ul>

                <p>Please note that by using the Game, you agree to the terms of this Privacy Policy. If you have any
                    questions or concerns about this policy, please contact us at [email address].</p>

            </Modal.Body>
        </Modal>
    </>;
}

export default PrivacyPolicy;