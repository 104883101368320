import {Modal} from "react-bootstrap";
import horse_face from "../assets/horse-face.svg";

function Rules({show, handleClose}) {

    return <>
        <Modal show={show} onHide={handleClose} keyboard={true} size="lg" className={"tilt-in-fwd-bl"}>
            <Modal.Header className={"border-warning bg-warning-subtle shadow shadow-sm pt-1 pb-1"} closeButton>
                <Modal.Title className={"text-yellow-200-racing text-warning d-flex"}>
                    Rules & How To Win
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"bg-gradient-yellow rounded-bottom-2"}>
                <h4>Jackpot Reward:</h4>
                <ul>
                    <li>To win this reward, participants need to accurately predict the finishing order of all 6
                        horses.
                    </li>
                    <li>Correct sequence: Horse 1, Horse 2, Horse 3, Horse 4, Horse 5, Horse 6.</li>
                    <li>Those who successfully predict this exact sequence will share the jackpot reward.</li>
                </ul>

                <h4>Predicting Top 3 Horses:</h4>
                <p>
                    To win this reward, participants need to predict the top 3 finishing horses in their exact order.
                    Reward distribution:
                </p>
                <ul>
                    <li>1st Place: Correct prediction of the first-place horse.</li>
                    <li>2nd Place: Correct prediction of the second-place horse.</li>
                    <li>3rd Place: Correct prediction of the third-place horse.</li>
                </ul>

                <h4>Reward for Any Finishing Order:</h4>
                <ul>
                    <li>To win this reward, participants need to predict a specific order of horses finishing,
                        regardless of the actual finishing order.
                    </li>
                    <li>Only one prediction can be submitted; those who submit multiple predictions won't be eligible
                        for the reward.
                    </li>
                    <li>If the horses finish in the order predicted by the participant, they will win the reward.</li>
                    <li>In essence, there are different reward categories and conditions based on the race's outcome.
                        Players make predictions according to these conditions, and they earn rewards based on the
                        accuracy of their predictions.
                    </li>
                </ul>
            </Modal.Body>
        </Modal>
    </>;
}

export default Rules;