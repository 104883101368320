import {
    Button,
    Col, Row
} from 'react-bootstrap';
import axios from "axios";
import {useEffect, useState} from "react";
import {ApiBase} from "../Config.js";

function PaidForm({raceId}) {
    const [paidInfo, setPaidInfo] = useState();
    const [gameId, setGameId] = useState(raceId);

    const handlerTxSaveClick = () => {

        alert("you hacked us!!");

    }

    useEffect(() => {

    }, []);

    return (<>
        <Row>
            <Col><input type={"text"} className={"form-control form-control-sm"} onChange={(e) => setPaidInfo(e.target.value)} /></Col>
            <Col>
                <Button className={"btn btn-sm btn-success"} onClick={() => { handlerTxSaveClick() }} >Paid</Button>
            </Col>
        </Row>
    </>)
}

export default PaidForm;