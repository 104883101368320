import coins from "../assets/coins.png";

function BuyFltrButton({width}) {

    return <a href={"https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x09824592081B87924b1B8371a747edfd369c06de"}
              target={"_blank"} className={" ms-2"} rel="noreferrer"><img src={coins}
                                                                          className={"img-fluid"}
                                                                          width={width}
                                                                          alt={""}/></a>;
}

export default BuyFltrButton;