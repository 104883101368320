import {Modal} from "react-bootstrap";

function TermsOfConditions({show, handleClose}) {

    return <>
        <Modal show={show} onHide={handleClose} keyboard={true} size="lg">
            <Modal.Header className={"border-warning bg-warning-subtle shadow shadow-sm pt-1 pb-1"} closeButton>
                <Modal.Title className={"text-yellow-200-racing text-warning d-flex"}>
                    Terms Of Conditions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"bg-gradient-yellow rounded-bottom-2"}>
                <h2>Terms and Conditions</h2>

                <p>These Terms and Conditions govern your use of our online horse racing game ("the Game"). By accessing and using the Game, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please do not use the Game.</p>

                <h3>1. Eligibility</h3>

                <p>You must be at least 18 years old to play the Game. By accessing and using the Game, you confirm that you meet this age requirement.</p>

                <h3>2. Betting and Wagering</h3>

                <p>
                    <strong>2.1. Bets and Wagers:</strong> Bets and wagers are made using the Full Throttle (FLTR) Token. You can acquire FLTR Tokens through designated platforms.
                </p>

                <p>
                    <strong>2.2. Betting Odds:</strong> Betting odds are determined based on the racing attributes and performance of the horses. These odds are subject to change without notice.
                </p>

                <p>
                    <strong>2.3. Finality of Bets:</strong> All bets and wagers are final. No changes or cancellations can be made once a bet is placed.
                </p>

                <h3>3. Jackpot</h3>

                <p>
                    <strong>3.1. Winning the Jackpot:</strong> To win the Jackpot, you must correctly predict the finishing order of the six horses in a specified race. The Jackpot winner will be awarded a substantial prize.
                </p>

                <h3>4. Payouts</h3>

                <p>
                    <strong>4.1. Payout Processing:</strong> Payouts are automatically processed daily after 12:00 PM. Winning players will receive their rewards in their wallets.
                </p>

                <p>
                    <strong>4.2. Wallet Addresses:</strong> Players are responsible for providing accurate wallet addresses for the receipt of their payouts. We are not liable for any issues arising from incorrect wallet addresses provided by players.
                </p>

                <h3>5. Security</h3>

                <p>
                    <strong>5.1. Data Security:</strong> We employ advanced cryptographic technologies and security measures to ensure the safety of user data and transactions.
                </p>

                <h3>6. Support</h3>

                <p>
                    <strong>6.1. Support Team:</strong> A support team is available to address your queries and concerns related to the Game. Please reach out to them for assistance.
                </p>

                <h3>7. Modifications</h3>

                <p>
                    <strong>7.1. Game Changes:</strong> We reserve the right to modify, suspend, or discontinue the Game, or any part thereof, at any time without prior notice.
                </p>

                <h3>8. Governing Law</h3>

                <p>
                    <strong>8.1. Legal Jurisdiction:</strong> These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which the Game is provided.
                </p>

                <p>By accessing and using the Game, you acknowledge that you have read and understood these Terms and Conditions, and you agree to abide by them. If you do not agree to these Terms and Conditions, please refrain from using the Game.</p>

                <p>These Terms and Conditions were last updated on [Date].</p>

                <p>For any inquiries or concerns regarding these Terms and Conditions, please contact our support team at [Contact Email].</p>

            </Modal.Body>
        </Modal>
    </>;
}

export default TermsOfConditions;