import {
    Card,
    Container,
    Table
} from 'react-bootstrap';
import axios from "axios";
import {useEffect, useState} from "react";
import {ApiBase} from "../Config.js";
import "react-toastify/dist/ReactToastify.css";
import PaidForm from "../components/PaidForm";

function Results() {
    const [winners, setWinners] = useState();
    useEffect(() => {
        const config = {
            method: 'get',
            url: `${ApiBase}/todays/results`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                setWinners(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    return (<Container className={"mt-4"}>
        <Card>
            <Card.Header>Kazananlar</Card.Header>
            <Table striped={true} responsive={true} variant={"dark"}>
                <thead>
                <tr>
                    <th>Race Id</th>
                    <th>Details</th>
                </tr>
                </thead>
                <tbody>
                {winners && (() => {
                    const list = Object.values(winners)[1].data;
                    return list.map((v, i) => {
                        return <tr key={i}>
                            <td>{v.game_no}</td>
                            <td>
                                <Table striped={true}>
                                    <thead>
                                    <tr>
                                        <th>Account</th>
                                        <th>Prize</th>
                                        <th>Horses</th>
                                        <th>Tx Id</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {v?.winners && v.winners.map((vv, ii) => {
                                        return <tr key={`d${ii}`}>
                                            <td>{vv.account}</td>
                                            <td>{vv.prize}<small className={"text-xsm"}>FLTR</small></td>
                                            <td>
                                                <ul>
                                                    {vv.horses.map((horse, index) => {
                                                        return <li key={index + "d"}>{horse.order} - {horse.name}</li>
                                                    })}
                                                </ul>
                                            </td>
                                            <td>
                                                <PaidForm />
                                            </td>
                                        </tr>
                                    })}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    })
                })()
                }
                </tbody>
            </Table>
        </Card>
    </Container>)
}

export default Results