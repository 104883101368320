import {
    Badge,
    Button,
    Card,
    Col,
    Form, ListGroup,
    Modal,
    OverlayTrigger,
    Popover,
    Row
} from 'react-bootstrap';
import _ from "lodash";
import axios from "axios";
import {useEffect, useState} from "react";
import useWebSocket from 'react-use-websocket';
import Web3 from "web3";
import {FLTRContractABI, contractAddress, paymentWallet, ApiBase, wsBase} from "../Config.js";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';

import Rules from "../page/Rules";
import racer1 from '../assets/subject/1.svg';
import racer2 from '../assets/subject/2.svg';
import racer3 from '../assets/subject/3.svg';
import racer4 from '../assets/subject/4.svg';
import racer5 from '../assets/subject/5.svg';
import racer6 from '../assets/subject/6.svg';
import racer7 from '../assets/subject/7.svg';
import racer8 from '../assets/subject/8.svg';
import racer9 from '../assets/subject/9.svg';
import racer10 from '../assets/subject/10.svg';
import racer11 from '../assets/subject/11.svg';
import racer12 from '../assets/subject/12.svg';
import racer13 from '../assets/subject/13.svg';


import bill_img from '../assets/bill.svg';
import horse_icon from '../assets/horse-icon.png';
import horse_face from '../assets/horse-face.svg';
import help_icon from '../assets/question-mark.svg';
import racing from '../assets/racing.svg';
import chronometer from '../assets/chronometer-2.svg';
import cup from '../assets/cup.svg';
import horse_shoe from '../assets/horse-shoe.png';

import Timer from "./Timer";
import EndCountdown from "./EndCountdown";
import Chronometer from "./Chronometer";
import Header from "./Header";
import BuyFltrButton from "./BuyFltrButton";
import Numbers from "./Numbers";

const WS_URL = wsBase;

const LastWinnersShow = ({message}) => {
    const entity = JSON.parse(message.data);
    return (<div>
        <ListGroup>
            <ListGroup.Item className={"btn btn-lg text-yellow-200-racing text-warning mb-2 d-flex fs-2 justify-content-around"}><img src={cup} height={60} alt={""} /> Winners <img src={cup} height={60} alt={""} /></ListGroup.Item>
            {entity[1].map((v, i) => {
                //console.log(v);
                return <ListGroup.Item
                    as="li"
                    className={`d-flex justify-content-between align-items-start ${(i === 0) ? "rounded-top-2":""}`} key={i}>
                    <div className={"me-auto"}>
                        {`0x${v.account?.slice(2, 2)}...${v.account?.slice(-4)}`}
                    </div>
                    <Badge bg={"warning"} className={"me-2"} pill>Won</Badge>
                    <Badge bg={"success"} pill>{v.prize}<small className={"text-xsm"}>FLTR</small></Badge>
                </ListGroup.Item>;
            })}
            <ListGroup.Item className={"btn btn-sm d-flex fs-5 justify-content-around"}>Close</ListGroup.Item>

        </ListGroup>
    </div>)
}

function Room() {
    const [show, setShow] = useState(false);
    const [showTicket, setShowTicket] = useState(false);
    const [endCountdown, setEndCountdown] = useState(false);
    const [winners, setWinners] = useState();
    const [timer, setTimer] = useState(false);
    const [subject, setSubject] = useState();
    const [start_state, setStartState] = useState();
    const [prices, setPrices] = useState();
    const [selectedRacers, setRacer] = useState([]);
    const [total, setTotal] = useState(0);
    const [userReady, setUserReady] = useState(false);
    const [multiplier, setMultiplier] = useState(1);
    const [prize, setPrize] = useState(0);
    const [gameId, setGameId] = useState(0);
    const [chronometerControl, setChronometerControl] = useState("");
    const [connected, setConnected] = useState(false);
    const [account, setAccount] = useState();
    const [balance, setBalance] = useState(0);
    const [ticket, setTicket] = useState();
    const [aboutStart, setAboutStart] = useState(false);
    const [showRules, setShowRules] = useState(false);
    const [fltrPrice, setFLTRPrice] = useState();
    const [farSubject, setFarSubject] = useState(0);

    const images = [
        racer1,
        racer2,
        racer3,
        racer4,
        racer5,
        racer6,
        racer7,
        racer8,
        racer9,
        racer10,
        racer11,
        racer12,
        racer13,
    ];

    const handleClose = () => {
        setShow(false);
        setShowRules(false);
    };

    const handleShow = (data) => () => { // Ok işlevi kullanıyoruz.
        setShow(!show);
    };
    const sendRoomMessage = (message) => {
        sendMessage(message);
    };

    const {sendMessage} = useWebSocket(WS_URL, {
        onOpen: () => {
            console.log('WebSocket connection established.');
            sendRoomMessage('room1');
        },
        onMessage: (message) => {
            //console.log(message.data);
            if (message.data.includes("end")) {
                const entity = JSON.parse(message.data);
                setChronometerControl("stop");
                /*toast.info(`Race ${gameId} ended`);*/
                setWinners(entity[1]);
                setEndCountdown(true);
                setShow(true);

                setTimeout(() => {
                    setUserReady(true);
                    sessionStorage.setItem("bp", JSON.stringify({status: false}));
                    sessionStorage.setItem("bet", "");
                    sessionStorage.clear();
                }, 2 * 60 * 1000);
            } else if (message.data.includes("about_start")) {
                aboutStartHandler();
            } else if (message.data.includes("last_winners")) {
                toast(<LastWinnersShow message={message}/>, {
                    type: "success",
                    position: "top-left",
                    icon: false,
                    autoClose: 30000,
                    closeButton: false,
                    theme: "colored"
                });
            } else if (message.data.includes("startIn5seconds")) {
                startIn5Seconds();
            } else {
                const entity = JSON.parse(message.data);
                setSubject(entity[1]);
                if(entity[1] !== undefined) {
                    const maxStep = _.maxBy(entity[1], 'step').step;
                    setFarSubject(100);
                }

                setChronometerControl("start");
                setTimer(false);
                sessionStorage.setItem("startIn5seconds", 0);
            }
        }
    });
    const startIn5Seconds = () => {
        setUserReady(true);
        setTimer(true);
        toast.info("Race starts in 5 Seconds", {
            icon: ({theme, type}) => <img src={chronometer} alt={"Going to Start In 5 Seconds"}
                                          className={"img-fluid"}/>
        });
        sessionStorage.setItem("bp", JSON.stringify({status: true}));
        sessionStorage.setItem("startIn5seconds", 1);
        sessionStorage.setItem("aboutStart", 0);
        setChronometerControl("reset");
    }
    const aboutStartHandler = () => {
        toast.info("New race going to start", {
            icon: ({theme, type}) => <img src={racing} alt={"About the Start"}/>
        });
        toast.info("Please wait for reload", {
            autoClose: false,
            closeButton: false,
            icon: ({theme, type}) => <img src={horse_shoe} className={"img-fluid rotate-in-center"}
                                          alt={"About the Start"}/>
        });
        setUserReady(true);
        setChronometerControl("reset");
        setTimer(true);
        setAboutStart(true);
        sessionStorage.clear();
        sessionStorage.setItem("aboutStart", 1);
        setTimeout(() => {
            document.location.reload();
        }, 1000 * 30)
    }

    useEffect(() => {
        const bp = JSON.parse(sessionStorage.getItem("bp"));
        setUserReady(bp?.status);
        axios.get(`${ApiBase}/race`).then((v, i) => {
            const items = v.data;
            setGameId(items[0]);
            const starterStateItems = items[1].map((v, i) => {
                return {force: 0, speed: 1.532941308519255, move: false, step: 0, horse: v};
            });

            setStartState(starterStateItems);
        }).catch((error) => {
            toast.error("Connection error. Try Again later");
            setTimeout(() => {
                document.location.reload();
            }, 60000);
        });

        setSubject(start_state);

        setPrices([
            {price: 20}
        ]);

        connectToMetaMask();

        getPrice().then(res => {
            setFLTRPrice(Number(res.data.ftvt).toFixed(6));
        })

    }, []);

    useEffect(() => {
        setEndCountdown(true);
        if (connected) {
            getBalance();
            /*Login({wallet: account}).then((res) => {
                sessionStorage.setItem("user", JSON.stringify(res.user));
            });*/
        }
    }, [connected]);

    const handleCheckboxChange = (index) => (event) => {
        const checkedIndex = selectedRacers.indexOf(index);

        if (event.target.checked && checkedIndex === -1) {
            // Checkbox checked and not already in the array
            console.log(selectedRacers.length + 1);
            if (selectedRacers.length + 1 <= 6) {
                setRacer([...selectedRacers, index]);
            } else {
                toast.error("You can only choose 6 Horses")
            }
            setShowTicket(true);
        } else if (!event.target.checked && checkedIndex !== -1) {
            // Checkbox unchecked and exists in the array
            setRacer(selectedRacers.filter((item) => item !== index));
        }

        const Ticket = selectedRacers.map((v, i) => {
            //Message += `${(i + 1)} ${start_state[v].horse.name}<br />`;
            return {...start_state[v].horse};
        });
        setTicket(Ticket);
    };

    useEffect(() => {
        // This will run whenever selectedRacers changes
        const price = prices ? prices[0]?.price : 0.07;
        let totalPrize = 0;
        selectedRacers.map(v => {
            totalPrize += Number(start_state[v].horse.odds) * price;
        });

        //setPrize(price * selectedRacers.length * (multiplier + 1));
        setPrize(totalPrize * (multiplier) + total);

        setTotal(price * selectedRacers.length * multiplier);
    }, [selectedRacers, prize, multiplier]);

    const handleReadyButtonClick = async () => {

        /*
                if (multiplier > 1) {
                    await placeBet(total * multiplier)
                } else {
                    await placeBet(total);
                }
        */

        await setUserReady(1);
        await lockBet();
    };

    const handleMultiplierClick = (val) => {
        setMultiplier(val);
    }

    const connectToMetaMask = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({method: "eth_requestAccounts"});
                const provider = new Web3(window.ethereum);
                await window.ethereum.request({method: "eth_chainId", params: ["0x38"]});
                const accounts = await provider.eth.getAccounts();

                setConnected(true);
                setAccount(accounts[0]);
            } catch (error) {
                console.log(error);
            }
        } else {
            //console.error("Please install MetaMask to use this application.");
            toast.error("Please install MetaMask to use this application.");
        }
    };

    const disconnectFromMetaMask = () => {
        setConnected(false);
        setAccount(null);
    };

    const getBalance = async () => {
        try {
            const provider = new Web3(window.ethereum);
            const contract = new provider.eth.Contract(FLTRContractABI, contractAddress);
            const balance = await contract.methods.balanceOf(account).call();
            const decimals = 8;
            const balanceFormatted = (parseInt(balance) / Math.pow(10, decimals)).toString();

            setBalance(Number(balanceFormatted));
        } catch (error) {
            console.log(error);
        }
    };

    const lockBet = async () => {
        try {
            const provider = new Web3(window.ethereum);
            const tokenContract = new provider.eth.Contract(FLTRContractABI, contractAddress);

            const decimals = 8;
            const amountToSend = (total * 10 ** decimals).toString();

            const transferResult = await tokenContract.methods.transfer(paymentWallet, amountToSend).send({
                from: account,
            });

            console.log("success", transferResult);
            toast.success("Your bet placed to blockchain");

            const config = {
                method: 'post',
                url: `${ApiBase}/b@/place`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    "gameId": gameId,
                    "user": account,
                    "bet": {
                        "horses": selectedRacers.map(v => {
                            return start_state[v]
                        }),
                        "multiplier": multiplier,
                        "total_amount": total
                    },
                    "transfer_result": convertBigIntToString(transferResult)
                })
            };

            axios(config)
                .then(function (response) {
                    console.log(response.data);
                    sessionStorage.setItem("bp", JSON.stringify({status: true}));
                    sessionStorage.setItem("bet", config.data);
                    toast.success("Your bet succesfully saved");

                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Your bet saving failed, please contact site owner.");

                });

        } catch (error) {
            console.log("eerror", error);

            toast.error(error);
        }
    };

    const getPrice = async () => {
        const api_url = "https://shop-api.rpd.ink/api/v1/token/fltr/price";
        const prices = await axios.get(api_url);

        return prices;
    };

    function convertBigIntToString(obj) {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                // Özyinelemeli olarak iç nesneleri de kontrol ediyoruz
                obj[key] = convertBigIntToString(obj[key]);
            } else if (Array.isArray(obj[key])) {
                obj[key] = convertBigIntToString(obj[key]);
            } else {
                obj[key] = `${obj[key]}`;
            }
        }
        return obj;
    }

    const popover = (
        <Popover id="popover-basic" style={{width: "220px"}} className={"border-success bg-success-subtle shadow-lg"}>
            <Popover.Header className={"bg-success-subtle text-success border-2 border-success shadow-sm"}>Game
                Ticket</Popover.Header>
            <Popover.Body>
                {
                    ticket ? selectedRacers.map((v, i) => {
                        return <Row className={""} key={i}>
                            <Col className={"p-0 col-8 border-bottom border-1 border-primary-subtle"}><span
                                className={"fw-bolder"}>{i + 1}.</span> {start_state[v].horse.name}</Col>
                            <Col className={"p-0 col-2"}><Badge bg="success"
                                                                className={"ms-4"}>{start_state[v].horse.odds}</Badge></Col>
                        </Row>;

                    }) : <Row><Col className={"text-danger fw-bolder"}>Choice 6 Horses</Col></Row>
                }

                <Row className={"border-0 border-top border-success mt-4"}>
                    <Col>Total:</Col>
                    <Col>{total}<small className={"text-xsm"}>FLTR</small></Col>
                </Row>
                <Row className={"border-0 border-top border-warning-subtle"}>
                    <Col>Prize:</Col>
                    <Col>{prize.toFixed(4)}<small className={"text-xsm"}>FLTR</small></Col>
                </Row>
                <Row className={"border-0 border-top border-warning-subtle mt-4"}>
                    <Col className={"d-flex justify-content-end"}>
                        <Button className={"btn btn-sm btn-success p-1 text-xsm"}
                                onClick={handleReadyButtonClick}
                                disabled={(userReady || total < 6)}>{userReady ? (timer ?
                            <Timer
                                seconds={5}></Timer> : "Waiting for start") : "Place a Bet"}</Button>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <Card className={""}>
                <Card.Body>

                    <Header account={account} balance={balance} connected={connected}
                            connectToMetaMask={connectToMetaMask}
                            disconnectFromMetaMask={disconnectFromMetaMask}></Header>
                    <Card className={"border-warning mb-2 p-0 shadow bg-top-header"}>
                        <Card.Body className={"p-2"}>
                            <Row>
                                <Col className={"border-1 border-warning border-end info"}
                                     style={{letterSpacing: "0.1rem"}}>
                                    {subject ? <>
                                        <div className={"position-absolute bg-danger"}
                                             style={{right: "70px", top: "100px", height: "405px", width: "2px"}}></div>
                                        <Row>
                                            <Col className={"d-flex align-content-end"}><Chronometer
                                                control={chronometerControl}/></Col>
                                        </Row></> : <>
                                        <span className={"fw-bold"}>To start: </span>
                                        <Timer seconds={120}></Timer>
                                    </>}

                                    <div className={"text-xsm"}><span
                                        className={"fw-bold"} style={{letterSpacing: "0.1rem"}}>Game Id:</span> {gameId}
                                    </div>
                                </Col>
                                <Col className={"border-1 border-warning border-end pt-1 info"}
                                     style={{letterSpacing: "0.1rem"}}>
                                    <span className={"fw-bolder"}>Total: </span>
                                    <span>{total} <span className={"small text-xsm"}>FLTR</span> <BuyFltrButton
                                        width={20}></BuyFltrButton></span>
                                    <div className={"text-xsm"}><span
                                        className={"fw-bold"}
                                        style={{letterSpacing: "0.1rem"}}>1 FLTR :</span> {fltrPrice} USDT

                                    </div>
                                </Col>
                                <Col className={"border-1 border-warning border-end d-flex pt-1 info"}
                                     style={{letterSpacing: "0.1rem"}}>
                                    <span className={"me-4 fw-bolder"}>Multiplier: </span>
                                    <Form.Check
                                        type={"radio"}
                                        id={`multiplier_0`}
                                        name={`multiplier`}
                                        label={`1X`}
                                        value={1}
                                        className={"small me-2"}
                                        disabled={userReady}
                                        onClick={() => handleMultiplierClick(1)}
                                        checked={(multiplier === 1)}
                                        onChange={() => handleMultiplierClick(1)}
                                    />
                                    <Form.Check
                                        type={"radio"}
                                        id={`multiplier_2`}
                                        name={`multiplier`}
                                        label={`2X`}
                                        value={2}
                                        className={"small me-2"}
                                        disabled={userReady}
                                        onClick={() => handleMultiplierClick(2)}
                                        onChange={() => handleMultiplierClick(2)}
                                        checked={(multiplier === 2)}
                                    />
                                    <Form.Check
                                        type={"radio"}
                                        id={`multiplier_2`}
                                        name={`multiplier`}
                                        label={`3X`}
                                        value={3}
                                        className={"small me-2"}
                                        disabled={userReady}
                                        onClick={() => handleMultiplierClick(3)}
                                        onChange={() => handleMultiplierClick(3)}
                                        checked={(multiplier === 3)}
                                    />
                                </Col>
                                <Col className={"border-1 border-warning border-end pt-1 info"}
                                     style={{letterSpacing: "0.1rem"}}>
                                    <span className={"fw-bolder"}>Your Prize: </span>
                                    <span>{prize.toFixed(4)} <span className={"small text-xsm"}>FLTR</span></span>
                                </Col>
                                <Col className={"border-1 border-warning border-end pt-1 info"}
                                     style={{letterSpacing: "0.1rem"}}>
                                    <span className={"fw-bolder"}>Jackpot: </span>
                                    <span>300 <span className={"small text-xsm"}>FLTR</span></span>
                                </Col>
                                <Col className={"d-flex justify-content-end info"}>
                                    <Button className={"btn btn-sm btn-warning me-2"}><img src={help_icon}
                                                                                           className={"img-fluid"}
                                                                                           style={{width: "24px"}}
                                                                                           alt={""}
                                                                                           onClick={() => setShowRules(!showRules)}/></Button>
                                    <OverlayTrigger trigger="click" placement="left" overlay={popover}
                                                    show={showTicket}>
                                        <Button variant="success" className={"me-2"}><img src={bill_img}
                                                                                          className={"img-fluid"}
                                                                                          style={{width: "24px"}}
                                                                                          alt={"Ticket"}
                                                                                          onClick={() => {
                                                                                              setShowTicket(!showTicket)
                                                                                          }}/></Button>
                                    </OverlayTrigger>
                                    <Button className={"btn btn-sm btn-success"}
                                            onClick={handleReadyButtonClick}
                                            disabled={(userReady || total < 6)}>{userReady ? (timer ?
                                        <Timer
                                            seconds={5}></Timer> : "Race Started") : "Place a Bet"}</Button>
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>

                    {subject?.length > 0 ? (

                        <Card>
                            <Card.Body className={"rounded shadow court"}>
                                {subject.map((item, index) => {
                                    return <Row key={index}
                                                style={{height: "50px"}}>
                                        <Col className="col-2 text-horse-name-list border-end border-success-subtle">
                                            <Row>
                                                <Col className={"col-9 text-end"}>{item.horse?.name}</Col>
                                                <Col className={"col-3 p-0"}><Numbers number={index}/></Col>
                                            </Row>
                                        </Col>
                                        <Col className={"col-10 running_line"}>
                                            <input
                                                type="range"
                                                value={item?.step || 0}
                                                max={farSubject}
                                                readOnly={true}
                                                className={`slider slider${index + 1}`}
                                            />
                                        </Col>
                                    </Row>
                                })}
                            </Card.Body>
                        </Card>

                    ) : (
                        <>

                            <Row className={"d-flex p-2"}>
                                {start_state && start_state?.map((item, index) => {
                                    return <Col className={"p-0 me-1 horse_anim"} key={index}>
                                        <Card className={"shadow rounded-bottom-4 bg-success-light"}
                                              style={{minHeight: "440px", maxWidth: "162px"}} key={`state${index}`}>
                                            <img src={images[item.horse.image -1 ]}
                                                 className={"card-img p-2 rounded-bottom-0"}
                                                 alt={`${item.horse.name}`}/>
                                            <Card.Body
                                                className={"bg-gradient-green rounded-bottom-4"}>

                                                <div className={"text-horse-name mt--10"}
                                                     style={{minHeight: "40px"}}>{item.horse.name}</div>
                                                {/*{selectedRacers.includes(index) &&
                                                    <div>{Number(_.findIndex(selectedRacers, item => item === index)) + 1}</div>}*/}

                                                <ul className={"list-group border-0 m-0"}>
                                                    <li className={"list-group-item list-group-item-warning border-1 border-warning rounded-2 small shadow text-success info"}>
                                                        <strong
                                                            style={{letterSpacing: "0.1rem"}}>Odds:</strong> {item.horse.odds}
                                                    </li>

                                                    <li className={"list-group-item list-group-item-success small p-0 shadow-sm text-success info border-1 my-2 rounded-2 pt-1 ps-2"}>
                                                        <Form.Check // prettier-ignore
                                                            type={"switch"}
                                                            id={`racer${index + 1}`}
                                                            value={index}
                                                            label={"Add To Ticket"}
                                                            checked={selectedRacers.includes(index)}
                                                            onChange={handleCheckboxChange(index)}
                                                            disabled={userReady}
                                                            data-bs-container={"body"} data-bs-toggle={"popover"}
                                                            data-bs-placement={"top"} data-bs-content={"Top popover"}
                                                        />
                                                    </li>

                                                    <li className={"list-group-item small p-1 text-green-200 rounded-top-2"}>
                                                        <span
                                                            className={"fw-bold properties_list_item"}>Type</span>: {item.horse.type.name}
                                                    </li>
                                                    <li className={"list-group-item small p-1 text-green-200"}>
                                                        <span
                                                            className={"fw-bold properties_list_item"}>Speed</span>: {item.horse.speed}
                                                    </li>
                                                    <li className={"list-group-item small p-1 text-green-200"}>
                                                        <span
                                                            className={"fw-bold properties_list_item"}>Stamina</span>: {item.horse.stamina}
                                                    </li>
                                                    <li className={"list-group-item small p-1 text-green-200"}>
                                                        <span
                                                            className={"fw-bold properties_list_item"}>Agility</span>: {item.horse.agility}
                                                    </li>
                                                    <li className={"list-group-item small p-1 text-green-200"}>
                                                        <span
                                                            className={"fw-bold properties_list_item"}>Morale</span>: {item.horse.morale}
                                                    </li>
                                                    <li className={"list-group-item small p-1 text-green-200"}><span
                                                        className={"fw-bold properties_list_item"}>Age</span>: {item.horse.age}
                                                    </li>
                                                    {/*<li className={"list-group-item small p-1"}>&nbsp;</li>*/}


                                                </ul>
                                            </Card.Body>

                                        </Card>


                                    </Col>
                                })}
                            </Row>
                        </>
                    )}
                </Card.Body>
            </Card>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} className={"puff-in-center"}>
                <Modal.Header className={"border-warning bg-warning-subtle shadow shadow-sm pt-1 pb-1"}>
                    <Modal.Title className={"text-yellow-200-racing text-warning d-flex"}>
                        Race Ended
                    </Modal.Title>
                </Modal.Header>
                {aboutStart ? <Modal.Body className={"bg-gradient-yellow rounded-bottom-2"}>
                    <Row>
                        <Col className={"text-description-200 pt-3"}>
                            Preparing horses for the new game...
                        </Col>
                        <Col className={"col-2"}>
                            <img src={horse_face} className={"img-fluid"} alt={"Preparing"}/>
                        </Col>
                    </Row>
                </Modal.Body> : <Modal.Body className={"rounded-bottom-2 bg-gradient-yellow"}>
                    <Row>
                        <Col>
                            {
                                winners && <ul className={"list-group"}>
                                    {Object.values(winners).map((v, i) => {
                                        return <Row className={"small text-shadow-sm"} key={i}>
                                            <Col className={"small col-1 text-success"}><strong
                                                className={"align-self-start "}>{i + 1}. </strong></Col>
                                            <Col className={"small col-7"}>{v.horse.name}</Col>
                                            <Col className={"col-1 small"}>
                                                <img src={horse_icon}
                                                     style={{width: "24px"}} className={"me-2"} alt={v.horse.name}/>
                                            </Col>
                                            <Col className={"small col-2"}>
                                                <Badge bg={"success"} className={"ms-1 shadow shadow-sm"}
                                                       title={"Finish"}>{v.step.toFixed(4)}</Badge>
                                            </Col>
                                        </Row>
                                    })}
                                </ul>
                            }
                        </Col>
                        <Col>

                            {ticket && winners ?
                                <>
                                    {
                                        selectedRacers.map((v, i) => {
                                            return <Row key={i}>
                                                <Col className={"small col-2 me-2 ms-2"}>
                                                    {
                                                        (start_state[v]?.horse?.name === Object.values(winners)[i]?.horse.name) ?
                                                            <Badge bg={"success"}>Win</Badge> :
                                                            <Badge bg={"danger"}>Fail</Badge>
                                                    }
                                                </Col>
                                                <Col className={"small fw-bolder col-1"}>{i + 1}.</Col>
                                                <Col className={"small"}>{start_state[v].horse.name}</Col>
                                            </Row>
                                        })
                                    }
                                </>
                                : <Row><Col className={""}>There is no bet for this
                                    run</Col></Row>
                            }

                        </Col>
                    </Row>
                </Modal.Body>}
                <Modal.Footer className={"text-yellow-200-racing text-warning bg-success-subtle"}>
                    Game going to start after {
                        endCountdown && <EndCountdown seconds={55+120} className={"ms-2"}/>
                    } seconds
                </Modal.Footer>
            </Modal>

            <Rules show={showRules} handleClose={handleClose}></Rules>

        </>
    );
}

export default Room;
