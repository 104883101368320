import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Results from './page/Results';
/*import reportWebVitals from './reportWebVitals';*/

const root = ReactDOM.createRoot(document.getElementById('root'));
const rootRender = () => root.render(
    <React.StrictMode>
        <BrowserRouter>

            <Routes>

                <Route path='/' element={<App />} />
                <Route path='/results' element={<Results/>} />

            </Routes>

        </BrowserRouter>
    </React.StrictMode>
);

const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register(
                'sw.js',
                {
                    scope: './',
                }
            );

            if (registration.installing) {
                console.log('Service worker installing');
            } else if (registration.waiting) {
                console.log('Service worker installed');
            } else if (registration.active) {
                console.log('Service worker active');

            }
        } catch (error) {
            console.error(`Registration failed with ${error}`);
        }
    }
};

(async () => {
    rootRender();
    await registerServiceWorker();
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
