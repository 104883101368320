import { useEffect, useState } from 'react';

function Timer({seconds}) {
    const [count, setCount] = useState(seconds);

    useEffect(() => {
        if (count > 0) {
            const timerId = setTimeout(() => {
                setCount(count - 1);
            }, 1000);

            // Temizlik işlemi: Komponent unmount edildiğinde zamanlayıcıyı temizle
            return () => clearTimeout(timerId);
        }
    }, [count]);

    return <span>{count > 0 ? `${count} seconds` : 'About to start...'}</span>;
}

export default Timer;
