import { useEffect, useState } from 'react';

function EndCountdown({seconds, className=""}) {
    const [count, setCount] = useState(seconds);

    useEffect(() => {
        if (count > 0) {
            const timerId = setTimeout(() => {
                setCount(count - 1);
            }, 1000);

            // Temizlik işlemi: Komponent unmount edildiğinde zamanlayıcıyı temizle
            return () => clearTimeout(timerId);
        }
    }, [count]);

    return <div className={"text-yellow-200-racing text-warning fs-4 " + className}>{count > 0 ? `${count}` : 'Calculating'}</div>;
}

export default EndCountdown;
