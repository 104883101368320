import num0 from "../assets/numbers/0.svg";
import num1 from "../assets/numbers/1.svg";
import num2 from "../assets/numbers/2.svg";
import num3 from "../assets/numbers/3.svg";
import num4 from "../assets/numbers/4.svg";
import num5 from "../assets/numbers/5.svg";
import num6 from "../assets/numbers/6.svg";
import num7 from "../assets/numbers/7.svg";
import num8 from "../assets/numbers/8.svg";
import num9 from "../assets/numbers/9.svg";
import num10 from "../assets/numbers/hash.svg";

const Numbers = ({number}) => {

    const nums = [
        num0,
        num1,
        num2,
        num3,
        num4,
        num5,
        num6,
        num7,
        num8,
        num9,
        num10
    ];

    return <><img src={nums[10]} alt={number} className={"img-fluid"} style={{width: "15px", marginRight: "-12px"}}/><img src={nums[number + 1]} alt={number} className={"img-fluid"} style={{width: "40px", marginRight:"-18px"}}/></>;


}

export default Numbers;
