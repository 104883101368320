import React, { useState, useEffect } from 'react';

function Chronometer({ control }) {
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let interval;

        if (isActive) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isActive]);

    useEffect(() => {
        if (control === 'start') {
            setIsActive(true);
        } else if (control === 'stop') {
            setIsActive(false);
        } else if (control === 'reset') {
            setIsActive(false);
            setSeconds(0);
        }
    }, [control]);

    return (
        <div>
            <p>{seconds} seconds</p>
        </div>
    );
}

export default Chronometer;
